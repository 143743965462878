import { graphql } from "gatsby"
import React from "react"
import "../assets/styles/index.scss"
import styles from "../templates/case-study.module.scss"

import { formatColour } from ".././helpers/formatColour"

import Header from "../components/case-studies/header"
import Results from "../components/case-studies/results"
import Video from "../components/case-studies/video"
import Scroll from "../components/global/scroll"
import Image from "../components/lazy-loaders/image"

import Style from "../components/case-studies/style"
import MainLayout from "../layouts/main_layout"

const Application = ({ data: { datoCmsCaseStudy, allDatoCmsCaseStudy } }) => {
  return (
    <MainLayout>
      <Scroll theme="light" />
      <Style colour={formatColour(datoCmsCaseStudy.highlightColour)} />
      <div
        id="intro"
        className="container"
        style={{
          backgroundColor: "#FFF",
          color: "#000",
        }}
      >
        <Header
          title={datoCmsCaseStudy.title}
          titleHighlight={datoCmsCaseStudy.titleHighlight}
          highlightColour={formatColour(datoCmsCaseStudy.highlightColour)}
        />
        {datoCmsCaseStudy.primaryVideoId !== null ? (
          <Video
            posterImage={datoCmsCaseStudy.primaryImage.url}
            videoId={datoCmsCaseStudy.primaryVideoId}
            highlightColour={formatColour(datoCmsCaseStudy.highlightColour)}
          />
        ) : (
          <Image
            className={styles.primaryImg}
            image={datoCmsCaseStudy.primaryImage.url}
            colour={formatColour(datoCmsCaseStudy.highlightColour)}
            ratio={0.5625}
            responsive={true}
            widths={[768, 1024, 1600, 2000]}
          />
        )}
        <div className={styles.content}>
          <div className={styles.left}>
            <h2 className={`display5 text-uppercase ${styles.heading}`}>
              The challenge
            </h2>
            <div
              className="content-block"
              dangerouslySetInnerHTML={{
                __html:
                  datoCmsCaseStudy.theChallengeNode.childMarkdownRemark.html,
              }}
            />
          </div>
          <div
            className={styles.right}
            data-hover={datoCmsCaseStudy.heuristic[0].name}
            data-hover-background={`rgba(${datoCmsCaseStudy.highlightColour.red},${datoCmsCaseStudy.highlightColour.green},${datoCmsCaseStudy.highlightColour.blue},${datoCmsCaseStudy.highlightColour.alpha})`}
          >
            <p
              className={`display4 ${styles.heading}`}
              dangerouslySetInnerHTML={{
                __html: datoCmsCaseStudy.heuristic[0].description,
              }}
            />
            <p className="label1">
              Behavioural insight
              {datoCmsCaseStudy.heuristic[0].name && (
                <span
                  dangerouslySetInnerHTML={{
                    __html:
                      "&nbsp;&nbsp;|&nbsp;&nbsp;" +
                      datoCmsCaseStudy.heuristic[0].name,
                  }}
                />
              )}
            </p>
          </div>
          <div className={styles.left}>
            <h2 className={`display5 text-uppercase ${styles.heading}`}>
              The solution
            </h2>
            <div
              className="content-block"
              dangerouslySetInnerHTML={{
                __html:
                  datoCmsCaseStudy.theSolutionNode.childMarkdownRemark.html,
              }}
            />
          </div>
        </div>

        {datoCmsCaseStudy.secondaryImage && (
          <>
            {datoCmsCaseStudy.secondaryImage.map((image, i) => (
              <Image
                key={`image-${i}`}
                className={styles.secondaryImg}
                image={image.url}
                colour={formatColour(datoCmsCaseStudy.highlightColour)}
                ratio={0.5625}
                responsive={true}
                widths={[768, 1024, 1600, 2000]}
              />
            ))}
          </>
        )}
        <Results
          statistics={datoCmsCaseStudy.statistics}
          summary={datoCmsCaseStudy.resultSummaryNode}
          additional_info={datoCmsCaseStudy.additionalInformation}
        />
      </div>
    </MainLayout>
  )
}

export default Application

export const query = graphql`
  query {
    datoCmsCaseStudy(slug: { eq: "white-night-melbourne" }) {
      title
      slug
      titleHighlight
      highlightColour {
        red
        blue
        alpha
        green
      }
      introduction
      primaryImage {
        url
      }
      secondaryImage {
        url
      }
      thumbnail {
        url
      }
      hoverText
      primaryVideoId
      theChallengeNode {
        childMarkdownRemark {
          html
        }
      }
      heuristic {
        name
        description
      }
      theSolutionNode {
        childMarkdownRemark {
          html
        }
      }
      resultSummaryNode {
        childMarkdownRemark {
          html
        }
      }
      statistics {
        title
        description
      }
      preview {
        image {
          url
        }
        colour {
          red
          blue
          alpha
          green
        }
      }
      additionalInformation {
        additionalInfoText
      }
    }
    allDatoCmsCaseStudy(sort: { fields: [position], order: ASC }) {
      edges {
        node {
          slug
          title
          titleHighlight
          highlightColour {
            red
            blue
            green
            alpha
          }
          preview {
            image {
              url
            }
            colour {
              red
              blue
              green
              alpha
            }
            highlightColour {
              red
              blue
              green
              alpha
            }
          }
        }
      }
    }
  }
`
